<template>
	<section class="product-details center" :key="id">
		<template v-if="$root.rwd < 2">
			<lottie :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
		</template>
		<div style="width:100%; max-width:1200px; z-index:2;">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/product">百貨舖</el-breadcrumb-item>
				<el-breadcrumb-item>{{_product.name}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="row" align-x="right" align-y="center" gutter="5">
				<router-link to="/cart">
					<el-button icon="el-icon-shopping-cart-1" type="text" style="font-size:1em; color:#000;">購物車</el-button>
				</router-link>
				<b>｜</b>
				<router-link to="/order">
					<el-button icon="el-icon-s-order" type="text" style="font-size:1em; color:#000;">查看訂單</el-button>
				</router-link>
			</div>
			<br>
			<br>
			<div class="row-grid" gutter-x="30" gutter-y="20">
				<div class="rwd-m-6">
					<template v-if="_product.pictures && _product.pictures.length">
						<el-carousel-custom :list="_product.pictures" autoplay :interval="3000" indicator-position="outside" arrow="never" loop>
							<template slot-scope="{scope}">
								<img :src="scope.url" width="100%">
							</template>
						</el-carousel-custom>
					</template>
					<thumb v-else />
				</div>
				<div class="col rwd-m-6">
					<h1 style="margin-top:0;">{{_product.name}}</h1>
					<br>
					<div class="col" align-x="left" style="font-size:1.3em;">
						<pre>{{_product.title}}</pre>
						<table>
							<tr>
								<th>定價</th>
								<td>{{_product.price | currencyFilter}}</td>
							</tr>
							<tr>
								<th>椅友價</th>
								<td>{{_product.special_price | currencyFilter}}</td>
							</tr>
						</table>
					</div>
					<br>
					<shopping-section />
					<br>
					<el-divider />
					<div v-html="_product.details" />
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	components: {
		Thumb: () => import("@/components/thumb"),
		ElCarouselCustom: () => import("@/components/el-carousel-custom"),
		ShoppingSection: () => import("@/components/product/shopping-section"),
	},
	props: ["id"],
	computed: {
		...mapState("product", [
			"_product",
			"_autocomplete_option"
		]),
	},
	methods: {
		...mapActions("product", [
			"_getData",
			"_getAutocompleteOption",
		]),
		fetchSuggestions(key) {
			return (string, callback) => {
				const list = this._autocomplete_option[key] || new Array;
				const results = string
					? list.filter(item => item.includes(string))
					: list;
				// 調用 callback 返回建議列表的數據
				callback(results.map(value => ({ value })));
			}
		},
	},
	async created() {
		await this._getData(this.id);
		if (!Object.keys(this._autocomplete_option).length) await this._getAutocompleteOption();
	}
}
</script>

<style lang="scss" scoped>
.product-details {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush2.png"),
		url("../../../assets/background/dot1.png"),
		url("../../../assets/background/dot2.png");
	background-position: center 70%, center 80%, center 80%;

	.lottie {
		position: absolute;
		width: 25%;
		max-width: 250px;
		top: 50%;
		left: 5%;
	}

	.thumb {
		width: 100%;
	}

	table {
		text-align: left;
		border-collapse: separate;
		border-spacing: 0.5em;
		margin-left: -0.5em;

		td {
			color: #cb2222;
		}
	}

	.el-divider {
		background: $theme-light;
		height: 2px;
	}
}
</style>